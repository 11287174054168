import 'babel-polyfill'
import 'js/polyfills/customEvent'
import 'js/polyfills/closest'
import 'lazysizes'

// =utils
import 'js/utils/breakpoints'
import 'js/utils/detectTouch'

// debug mode
if (document.querySelector('body').classList.contains('-debug')) {
    import(/* webpackChunkName: "debugger" */ 'js/utils/debugger')
}

//===============================================================/
//  =components
//===============================================================/

if (document.querySelector('.cookie-consent')) {
    import(
        /* webpackChunkName: "compCookieConsent" */ 'js/components/cookieConsent'
    )
}

if (document.querySelector('.js-instagram-feed')) {
    import(
        /* webpackChunkName: "instagramFeed" */ 'js/components/instagram-feed'
    )
}

if (document.querySelector('.js-swiper-container')) {
    import(/* webpackChunkName: "swiper" */ 'js/components/swiper')
}

//===============================================================/
//  =blocks
//===============================================================/

if (document.querySelector('.js-hero-banner')) {
    import(/* webpackChunkName: "blockHero" */ 'js/blocks/hero')
}

if (document.querySelector('.js-hero-text')) {
    import(/* webpackChunkName: "blockHero" */ 'js/blocks/hero-text')
}

if (document.querySelector('.js-background-title')) {
    import(
        /* webpackChunkName: "blockBackgroundImage" */ 'js/blocks/background-image'
    )
}

if (document.querySelector('.js-video-banner')) {
    import(/* webpackChunkName: "blockHero" */ 'js/blocks/video')
}

if (document.querySelector('.js-testimonial')) {
    import(/* webpackChunkName: "blockTestimonial" */ 'js/blocks/testimonial')
}

if (document.querySelector('.js-logocarousel')) {
    import(
        /* webpackChunkName: "blockLogoCarousel" */ 'js/blocks/logo-carousel'
    )
}

if (document.querySelector('.js-newscarousel')) {
    import(
        /* webpackChunkName: "blockRelatedNewsCarousel" */ 'js/blocks/relatednews-carousel'
    )
}

if (document.querySelector('.js-staff')) {
    import(/* webpackChunkName: "blockStaff" */ 'js/blocks/staff')
}

if (document.querySelector('.js-teammember-modal')) {
    import(/* webpackChunkName: "teamModal" */ 'js/blocks/team-modal')
}

if (document.querySelector('.js-partners-modal-container')) {
    import(/* webpackChunkName: "partnersModal" */ 'js/blocks/partners-modal')
}

if (document.querySelector('.js-hub')) {
    import(/* webpackChunkName: "blockHub" */ 'js/blocks/hub')
}

if (document.querySelector('.js-ie-image')) {
    import(/* webpackChunkName: "ieImageItems" */ 'js/blocks/ie-image')
}

if (document.querySelector('.rpaVideo')) {
    import(/* webpackChunkName: "logo" */ 'js/blocks/logo')
}

if (document.querySelector('.js-partners-logo')) {
    import(/* webpackChunkName: "partnersLogo" */ 'js/blocks/partners-logo')
}

if (document.querySelector('.js-video-banner-block')) {
    import(/* webpackChunkName: "videoBanner" */ 'js/blocks/videoBanner')
}

//===============================================================/
//  =layout
//===============================================================/

if (document.querySelector('.header-nav')) {
    import(/* webpackChunkName: "layoutNav" */ 'js/layouts/nav')
}

//===============================================================/
//  =pages
//===============================================================/

// if (document.querySelector('.page-specific')) {
//   import(/* webpackChunkName: "pageNameHere" */ 'js/pages/pageNameHere')
// }

//===============================================================/
//  =polyfills
//===============================================================/

if (document.querySelector('body')) {
    import(/* webpackChunkName: "layoutNav" */ 'js/polyfills/objectFit')
}
